
document.addEventListener('DOMContentLoaded', () => {

    const observerElements = document.querySelectorAll('[data-parallax]');

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    };



    const animateEffect = (element) => {
        let winH = window.innerHeight;
        const scrollTop = window.pageYOffset
        const scrollBottom = scrollTop + winH;

        const targetPosi = element.getBoundingClientRect().top + scrollTop;
        const setVal = (((scrollBottom - targetPosi) * 0.1) - 98).toFixed(2);

        element.style.transform = 'translate3d(0,' + (setVal * -1.5) + 'px,0)';

        requestAnimationFrame(() => animateEffect(element));

    };

    setTimeout(() => {

        observerElements.forEach(element => {
            element.observer = new IntersectionObserver(entry => {
                if (entry[0].intersectionRatio > 0) {

                    animateEffect(element)
                } else {
                    cancelAnimationFrame(element);
                }
            }, observerOptions);

            element.observer.observe(element);
        });
    }, 500);

    window.addEventListener('resize', () => winH = window.innerHeight);

});
