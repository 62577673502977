/*------------------------------------------------------------------
Import styles
------------------------------------------------------------------*/

import './../styles/style.scss';

/*------------------------------------------------------------------
Import modules
------------------------------------------------------------------*/

import './components/parallax';
import './components/inview';
// import './components/videoModal';

document.addEventListener('DOMContentLoaded', () => {


    if (window.location.hash !== '') {
        let el = document.querySelector(window.location.hash);

        if (el) {
            var bodyRect = document.body.getBoundingClientRect(),
                elemRect = el.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top;

            window.scroll({
                left: 0,
                top: offset - 220
            });


            setTimeout(() => {
                window.addEventListener('scroll', stickyHeader);
            }, 1000);
        }
    } else {
        window.addEventListener('scroll', stickyHeader);
    }



    const queryAll = document.querySelectorAll.bind(document);

    let parallaxItems = [];

    const scrollHandler = () => {
        let windowHeight = window.innerHeight;
        parallaxItems.forEach((item) => {
            let ratio = parseFloat(item.getAttribute('data-parallaxer'));
            let rect = item.getBoundingClientRect();

            if (rect.top < window.innerHeight && rect.bottom > 0) {
                let normalized = (rect.top - windowHeight) / (rect.top - rect.bottom - windowHeight);
                normalized = Math.max(0, Math.min(1, normalized));
                let y = normalized * ratio * rect.height;
                item.style.webkitTransform = 'translate(0, ' + y + 'px)';
                item.style.msTransform = 'translate(0, ' + y + 'px)';
                item.style.transform = 'translate(0, ' + y + 'px)';
            }
        });
    }


    parallaxItems = [...queryAll('[data-parallaxer]')];

    scrollHandler();

    window.addEventListener('scroll', scrollHandler);


    /*------------------------------------------------------------------
    Sticky header  
    ------------------------------------------------------------------*/

    let previousScrollPosition = 0;
    let scrollDelta = 5;
    let header = document.querySelector('.header');
    let headerHeight = (header) ? header.clientHeight / 3 : 0;

    const stickyHeader = () => {
        let st = window.scrollY;
        if (Math.abs(previousScrollPosition - st) <= scrollDelta) return;
        document.querySelector('.js-mega').classList.remove('active')
        if (st === 0) {
            header.classList.remove('nav-down');
            header.classList.remove('nav-up');
        } else if (st > previousScrollPosition && st > headerHeight) {
            header.classList.remove('nav-down');
            header.classList.add('nav-up');
        } else {
            if (st + window.innerHeight < document.body.clientHeight) {
                header.classList.add('nav-down');
                header.classList.remove('nav-up');
            }
        }
        previousScrollPosition = st;
    }



    [...queryAll('.js-postload')].forEach(item => {
        item.classList.remove('js-postload');
    });


});


const isInViewport = function (elem) {
    var distance = elem.getBoundingClientRect();
    return (
        distance.top >= 0 && distance.left >= 0 &&
        distance.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        distance.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

setTimeout(() => {
    for (var item of document.querySelectorAll('.js-in-view')) {
        if (isInViewport(item)) item.classList.add('inView');
    }
}, 1000);

window.addEventListener('scroll', function (event) {
    for (var item of document.querySelectorAll('.js-in-view')) {
        if (isInViewport(item)) item.classList.add('inView');
    }
}, false);




