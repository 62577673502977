/*------------------------------------------------------------------
Helpers
------------------------------------------------------------------*/

const query = document.querySelector.bind(document);
const queryAll = document.querySelectorAll.bind(document);

/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import tingle from 'tingle.js';


/*------------------------------------------------------------------
Templates
------------------------------------------------------------------*/

/**
* Vimeo Template
*/
const vimeo = (id) =>
/*html*/`<iframe src="https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&autoplay=1" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;


/**
* Youtube Template
*/
const youtube = (id) =>
/*html*/`<iframe width="560" height="315" src="https://www.youtube.com/embed/${id}?controls=0&autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

/**
* Close Icon
*/
const closeIcon = () =>
  /*html*/`<svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37"><g fill="none" fill-rule="evenodd" stroke="#000" stroke-width="3" transform="translate(1 1)"><line x2="34" y2="34"/><line x2="34" y1="34"/></g></svg>`;


/**
 * Video modal template
 * @param {*} id
 * @param {*} type (defaults to youtube)
 */
const videoModalTemplate = (id, type = 'youtube') => {
  return `<div class="modal__close">
            <a href="#" class="modal__close__icon [ js-close-modal ]">
            ${closeIcon()}
            </a>
         </div>
         <div class="modal">
            <div class="embed-container">
            ${(type && type.toLowerCase() === 'vimeo') ? vimeo(id) : youtube(id)}
            </div>
         </div>`;
}


/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let modal;

const addCloseHandler = () => {
    document.querySelector('.js-close-modal').addEventListener('click', (e) => {
        e.preventDefault();
        modal.close();
    });
};


document.addEventListener('DOMContentLoaded', () => {

  [...document.querySelectorAll('.js-video-modal')].forEach(videoModal => {

      videoModal.addEventListener('click', (e) => {

          e.preventDefault();

          modal = new tingle.modal({
              onClose() { modal.destroy() },
              onOpen() { addCloseHandler() }
          });

          modal.setContent(videoModalTemplate(videoModal.dataset.videoId, videoModal.dataset.videoType));

          modal.open();

      });
  });

});
